enum AUTH_ENDPOINTS {
  LOGIN = "/auth/login",
  LOGOUT = "/auth/logout",
  ONE_TAP = "/auth/onetap",
  REFRESH = "/auth/refresh",
}

enum USER_ENDPOINTS {
  BASE = "/user",
  ME = "/user/me",
  LIST = "/user/all",
  SET_DEVICE_TOKEN = "/user/setDeviceToken",
  INSTALLS = "/user/installs",
}

enum TASK_ENDPOINTS {
  FETCH = "/task/fetch",
  CONVERSE = "/task/converse",
  DELETE = "/task/delete",
  CREATE = "/task/create",
}

export const API_ENDPOINTS = {
  AUTH: AUTH_ENDPOINTS,
  USER: USER_ENDPOINTS,
  TASK: TASK_ENDPOINTS,
};

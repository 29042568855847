import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { brands } from "@fortawesome/fontawesome-svg-core/import.macro";

import { StyledFooterLogo } from "./styles";

const Footer = () => {
  return (
    <footer className="bg-light py-4 mt-auto position-relative overflow-hidden">
      <Container>
        <div className="pt-4">
          <Row className="justify-content-lg-between">
            <Col md="4" className="ml-lg-auto mb-5 mb-lg-0 d-none d-md-flex">
              <div className="mb-4">
                <StyledFooterLogo />
              </div>
            </Col>

            <Col md="4" className="mb-5 mb-lg-0">
              <h5 className="px-3">About</h5>

              <ul className="nav nav-sm nav-x-0 flex-column">
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://www.heymayo.com/aboutmayo"
                  >
                    Our Story
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://www.heymayo.com/mayospread"
                  >
                    How Far Mayo has Spread
                  </a>
                </li>
              </ul>

              <h5 className="px-3 mt-4">Safe Sharing</h5>
              <ul className="nav nav-sm nav-x-0 flex-column">
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://www.heymayo.com/safe-sharing"
                  >
                    Dos &amp; Don’ts
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="http://www.heymayo.com/safe-sharing#mayo-moments-examples"
                  >
                    Mayo Moment Examples
                  </a>
                </li>
              </ul>
            </Col>

            <Col md="4" className="mb-5 mb-lg-0">
              <h5 className="px-3">Resources</h5>

              <ul className="nav nav-sm nav-x-0 flex-column">
                <li className="nav-item">
                  <a className="nav-link" href="https://www.heymayo.com/press">
                    Press
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://www.heymayo.com/faq">
                    FAQ
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://www.heymayo.com/blog">
                    Blog
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://www.heymayo.com/what-makes-mayo-unique"
                  >
                    What makes Mayo unique
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://www.heymayo.com/spreadkindness"
                  >
                    Mayo for Residential Communities
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://www.heymayo.com/helping-businesses"
                  >
                    Mayo for Businesses
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="mailto:hello@heymayo.com">
                    Contact
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </div>

        <hr className="my-4" />

        <div className="space-1">
          <Row className="align-items-md-center mb-7">
            <Col md="12" className="text-center">
              <ul className="list-inline mb-0">
                <li className="list-inline-item">
                  <a
                    className="btn btn-xs btn-icon btn-soft-secondary"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    href="https://www.facebook.com/The-Mayo-app-1120799041365516"
                  >
                    <FontAwesomeIcon icon={brands("facebook")} />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="btn btn-xs btn-icon btn-soft-secondary"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    href="https://www.instagram.com/heymayoapp"
                  >
                    <FontAwesomeIcon icon={brands("instagram")} />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="btn btn-xs btn-icon btn-soft-secondary"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    href="https://twitter.com/heymayoapp"
                  >
                    <FontAwesomeIcon icon={brands("twitter")} />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="btn btn-xs btn-icon btn-soft-secondary"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    href="https://medium.com/@heymayo"
                  >
                    <FontAwesomeIcon icon={brands("medium")} />
                  </a>
                </li>
              </ul>
            </Col>
          </Row>

          <div className="w-md-75 text-lg-center mx-lg-auto">
            <p className="text-center text-muted small">
              © Mayo, {new Date().getFullYear()}. Made in Seattle.
            </p>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default React.memo(Footer);

enum ERROR_CODES_VS_MESSAGE {
  INTERNAL_SERVER_ERROR = "Something went wrong.",
  INVALID_USER_CREDENTIALS = "User in not a valid Mayo Business user.",
  LOGIN_DENIED = "Login was denied.",
  LOGIN_LINK_EXPIRED = "Login link has expired, please try again.",
}

export const getErrorMessage = (error?: Error) => {
  const code = error?.message as keyof typeof ERROR_CODES_VS_MESSAGE;

  return (
    ERROR_CODES_VS_MESSAGE[code] || ERROR_CODES_VS_MESSAGE.INTERNAL_SERVER_ERROR
  );
};

import { useMemo } from "react";
import { useQuery as useRQQuery, UseQueryOptions } from "react-query";
import { pickBy } from "ramda";

// types
import type { AxiosRequestHeaders } from "axios";

// utils
import { getErrorMessage } from "utils/errors";

// clients
import { getInstance } from "clients/rest";

export enum QUERY_METHODS {
  GET = "GET",
  POST = "POST",
}

interface Config<TResult, TVariables, TError>
  extends UseQueryOptions<TResult, TError> {
  method?: QUERY_METHODS;
  payload?: Partial<TVariables>;
  params?: Partial<TVariables>;
  headers?: AxiosRequestHeaders;
  ignoreErrorToast?: boolean;
}

const keyFilter = (_: any, key: string) =>
  !["method", "payload", "params", "headers", "ignoreErrorToast"].includes(key);

function useQuery<TResult = unknown, TVariables = unknown, TError = Error>(
  path: string,
  config: Config<TResult, TVariables, TError> = {
    method: QUERY_METHODS.GET,
  },
) {
  const queryConfig: UseQueryOptions<TResult, TError> = useMemo(() => {
    return pickBy(keyFilter, config);
  }, [config]);

  return useRQQuery(
    path,
    async () => {
      const result: TResult = await getInstance()
        .request({
          url: path,
          data: config.payload,
          params: config.params,
          headers: config.headers,
          method: config.method || QUERY_METHODS.GET,
        })
        .catch((error) => {
          const code = error.response?.data?.code;
          const errorInstance = new Error(code || "INTERNAL_SERVER_ERROR");
          errorInstance.stack = error.stack;

          if (!config.ignoreErrorToast && !queryConfig.retry) {
            window.showToast({
              body: getErrorMessage(errorInstance),
              accent: "danger",
            });
          }

          return Promise.reject(errorInstance);
        });

      return result;
    },
    // @ts-ignore
    queryConfig,
  );
}

export default useQuery;

import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { Toast } from "react-bootstrap";
import classnames from "classnames";
import { assoc, omit, clone } from "ramda";
import hyperid from "hyperid";

import {
  TOAST_ACCENTS,
  TOAST_DELAYS,
  TOAST_DURATIONS_TIMEOUT,
} from "./constants";

import {
  ToastWrapper,
  ToastContainer,
  ToastItem as ToastItemContainer,
} from "./styles";

export interface ToastItem {
  header: React.ReactNode;
  body: React.ReactNode;
  accent?:
    | "default"
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "danger"
    | "warning";
  show?: boolean;
  delay?: TOAST_DELAYS;
  autohide?: boolean;
}

const ToastComponent = () => {
  const [toastItemList, setToastItemList] = useState<StringTMap<ToastItem>>({});

  const removeItem = useCallback((id: string) => {
    setToastItemList((toastItemList) => omit([id], toastItemList));
  }, []);

  const toggle = useCallback(
    (id: string, show: boolean = false) => {
      setToastItemList((toastItemList) =>
        assoc(
          id,
          {
            ...toastItemList[id],
            show: show,
          },
          toastItemList,
        ),
      );

      if (!show) {
        setTimeout(() => {
          window.requestAnimationFrame(() => {
            removeItem(id);
          });
        }, 2000);
      }
    },
    [removeItem],
  );

  const addItem = useCallback((item: ToastItem) => {
    const id = hyperid().uuid;
    const clonedItem = clone(item);

    clonedItem.show = typeof item.show !== "boolean" ? true : !!item.show;
    clonedItem.accent =
      clonedItem.accent && TOAST_ACCENTS[clonedItem.accent]
        ? clonedItem.accent
        : "secondary";

    setToastItemList((toastItemList) => assoc(id, clonedItem, toastItemList));
  }, []);

  useEffect(() => {
    window.showToast = (item: ToastItem) => {
      addItem(item);
    };
  }, [addItem]);

  const toastItems = useMemo(() => {
    return Object.entries(toastItemList).map(
      ([
        id,
        {
          show,
          accent,
          header,
          body,
          autohide = true,
          delay = TOAST_DELAYS.MEDIUM,
        },
      ]) => {
        const hideDelay = delay ? TOAST_DURATIONS_TIMEOUT[delay] : undefined;

        return (
          <ToastItemContainer
            key={`toast_${id}`}
            className={`bg-${accent}`}
            show={show}
            autohide={autohide}
            delay={hideDelay}
            onClose={() => toggle(id, false)}
          >
            {header && (
              <div
                className={classnames("toast-header", {
                  "text-white": accent !== "secondary",
                })}
              >
                <strong className="me-auto">{header}</strong>
                <button
                  onClick={() => toggle(id, false)}
                  type="button"
                  className={classnames("btn-close me-2 m-auto", {
                    "btn-close-white": accent !== "secondary",
                  })}
                  data-bs-dismiss="toast"
                  aria-label="Close"
                ></button>
              </div>
            )}
            <Toast.Body
              className={classnames("d-flex fs-5", {
                "text-white": accent !== "secondary",
              })}
            >
              <div className="flex-grow-1">{body}</div>
              {!header && (
                <button
                  onClick={() => toggle(id, false)}
                  type="button"
                  className={classnames("btn-close me-2 m-auto", {
                    "btn-close-white": accent !== "secondary",
                  })}
                  data-bs-dismiss="toast"
                  aria-label="Close"
                ></button>
              )}
            </Toast.Body>
          </ToastItemContainer>
        );
      },
    );
  }, [toastItemList, toggle]);

  return (
    <ToastWrapper aria-live="polite" aria-atomic="true">
      <ToastContainer className="d-flex flex-column align-items-end me-4">
        {toastItems}
      </ToastContainer>
    </ToastWrapper>
  );
};

export default React.memo(ToastComponent);

import * as Sentry from "@sentry/nextjs";
import { Integrations as TracingIntegrations } from "@sentry/tracing";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://1019f96baf3b4277b2accec53c5faabc@o370947.ingest.sentry.io/5895328"',
  integrations: [new TracingIntegrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import * as Sentry from "@sentry/react";

import ErrorImage from "../../assets/images/svg/500-error.svg";

class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error, { extra: errorInfo });
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }

    return (
      <Container className="d-flex align-items-center min-vh-100">
        <Row className="d-flex align-items-center flex-grow-1">
          <Col className="mx-md-auto my-5" md="7" sm="12">
            <div className="mb-5">
              <h1 className="display-1 fw-normal mb-2">Oops!</h1>
              <p className="display-4 lead">Something went terribly wrong.</p>
            </div>
          </Col>
          <Col className="text-center mx-md-auto my-5" md="5" sm="12">
            <div className="position-relative mt-5">
              <ErrorImage className="py-5 w-75 h-75" />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ErrorBoundary;

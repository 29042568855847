import React, { useEffect, useState, useCallback } from "react";
import {
  Modal as RModal,
  ModalProps as RModalProps,
  Button,
  ButtonProps,
} from "react-bootstrap";

export interface ModalProps {
  header?: React.ReactNode;
  body?: React.ReactNode;
  size?: RModalProps["size"];
  closeButtonText?: string;
  successButtonText?: string;
  successButtonVariant?: ButtonProps["variant"];
  closeOnSuccess?: boolean;
  onSuccess?: (closeModal: () => void) => void;
  onCancel?: () => void;
}

interface ModalState extends ModalProps {
  show: boolean;
}

const DEFAULT_CONFIG: Partial<ModalProps> = {
  closeButtonText: "Cancel",
  successButtonText: "Save",
  successButtonVariant: "primary",
  closeOnSuccess: true,
};

const Modal: React.FC<{}> = () => {
  const [state, setState] = useState<ModalState>({
    show: false,
  });

  const {
    header,
    body,
    closeButtonText,
    successButtonText,
    successButtonVariant,
    closeOnSuccess,
    onSuccess,
    onCancel,
    show,
    size,
  } = state;

  const closeModal = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      show: false,
    }));
  }, []);

  const handleClose = useCallback(
    (hasSuccess?: boolean) => {
      if (hasSuccess) {
        onSuccess && onSuccess(closeModal);
        if (closeOnSuccess) {
          closeModal();
        }
      } else {
        onCancel && onCancel();
        closeModal();
      }
    },
    [closeOnSuccess, closeModal, onSuccess, onCancel],
  );

  useEffect(() => {
    window.showModal = (config: ModalProps) => {
      setState(() => ({
        ...DEFAULT_CONFIG,
        ...config,
        show: true,
      }));
    };
  }, []);

  return (
    <RModal
      size={size}
      show={show}
      backdrop="static"
      onHide={() => handleClose(false)}
    >
      {header && (
        <RModal.Header closeButton>
          <RModal.Title>{header}</RModal.Title>
        </RModal.Header>
      )}
      <RModal.Body>{body}</RModal.Body>
      <RModal.Footer>
        <Button
          className="border-none shadow-none"
          variant="white"
          onClick={() => handleClose(false)}
        >
          {closeButtonText}
        </Button>
        <Button
          variant={successButtonVariant}
          onClick={() => handleClose(true)}
        >
          {successButtonText}
        </Button>
      </RModal.Footer>
    </RModal>
  );
};

export default React.memo(Modal);

import { useMemo } from "react";
import { useMutation as useRQMutation, MutateOptions } from "react-query";
import { pickBy } from "ramda";

// utils
import { getErrorMessage } from "utils/errors";

// clients
import { getInstance } from "clients/rest";

export enum MUTATION_METHODS {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

interface Config<TResult, TError, TVariables>
  extends MutateOptions<TResult, TError, TVariables> {
  method?: MUTATION_METHODS;
  payload?: Partial<TVariables>;
  params?: Partial<TVariables>;
  ignoreErrorToast?: boolean;
}

const keyFilter = (_: any, key: string) =>
  !["method", "params", "ignoreErrorToast"].includes(key);

function useMutation<TVariables = unknown, TResult = unknown, TError = unknown>(
  path: string,
  config: Config<TResult, TError, TVariables> = {
    method: MUTATION_METHODS.POST,
  },
) {
  const restClientInstance = getInstance();

  const mutationConfig: MutateOptions<TResult, TError, TVariables> =
    useMemo(() => {
      return pickBy(keyFilter, config);
    }, [config]);

  return useRQMutation(async (data: TVariables) => {
    const result: TResult = await restClientInstance
      .request({
        url: path,
        data: { ...config.payload, ...(data || {}) },
        params: config.params,
        method: config.method || MUTATION_METHODS.POST,
      })
      .catch((error) => {
        const code = error.response?.data?.code;
        const errorInstance = new Error(code || "INTERNAL_SERVER_ERROR");
        errorInstance.stack = error.stack;

        if (!config.ignoreErrorToast) {
          window.showToast({
            body: getErrorMessage(errorInstance),
            accent: "danger",
          });
        }

        return Promise.reject(errorInstance);
      });

    return result;
  }, mutationConfig);
}

export default useMutation;

import styled from "@emotion/styled";

import LogoWhite from "../../assets/images/svg/logo.svg";

export const StyledFooterLogo = styled(LogoWhite)`
  position: absolute;
  left: -60px;
  top: 50px;
  width: 500px;
  max-width: 40%;
  z-index: 1;
  background-color: var(--bs-light);
`;

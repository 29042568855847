export enum TOAST_ACCENTS {
  "default" = "default",
  "primary" = "primary",
  "secondary" = "secondary",
  "info" = "info",
  "success" = "success",
  "danger" = "danger",
  "warning" = "warning",
}

export enum TOAST_DELAYS {
  SHORT = "SHORT",
  MEDIUM = "MEDIUM",
  LONG = "LONG",
}

export const TOAST_DURATIONS_TIMEOUT = {
  [TOAST_DELAYS.SHORT]: 2000, //2s
  [TOAST_DELAYS.MEDIUM]: 5000, //5s
  [TOAST_DELAYS.LONG]: 8000, //8s
};

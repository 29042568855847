import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { mergeDeepRight } from "ramda";
import { getApps } from "firebase/app";
import { getMessaging, deleteToken } from "firebase/messaging";

// types
import type Auth from "types/auth";

// constants
import { API_ENDPOINTS } from "constants/api";

const DEFAULT_CONFIG: AxiosRequestConfig = {
  baseURL: "/api",
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    Accept: "application/json; charset=utf-8",
  },
  validateStatus: (status) => status >= 200 && status < 400,
};

const instance: AxiosInstance = axios.create(DEFAULT_CONFIG);
let authToken: Nullable<string>;

instance.interceptors.response.use(
  (response) => response.data,
  (error: {
    config: AxiosRequestConfig;
    response: AxiosResponse;
    [key: string]: any;
  }) => {
    const status = error.response ? error.response.status : null;
    if (!status) {
      setTimeout(() => {
        instance.request(error.config);
      }, 1000);
      return;
    }

    if (authToken) {
      if (status === 401) {
        return axios
          .get(
            API_ENDPOINTS.AUTH.REFRESH,
            mergeDeepRight(DEFAULT_CONFIG, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("refreshToken")}`,
              },
            }),
          )
          .then(
            (response: AxiosResponse<Auth.RefreshTokenResponse>) =>
              response.data,
          )
          .then(({ authToken, refreshToken }) => {
            setAuthToken(authToken);
            setRefreshToken(refreshToken);

            instance.defaults.headers["Authorization"] = `Bearer ${authToken}`;
            if (error.config.headers) {
              error.config.headers["Authorization"] = `Bearer ${authToken}`;
            }

            return axios
              .request(error.config)
              .then((response) => response.data);
          })
          .catch((error) => {
            console.log(1111, error);
            return Promise.reject(error);
          });
      }
    }

    if (status === 403) {
      logout(true);
    }

    return Promise.reject(error);
  },
);

const getInstance = () => {
  return instance;
};

const getAuthToken = () => {
  return authToken;
};

const setAuthToken = (inputAuthToken?: Nullable<string>) => {
  authToken = inputAuthToken;
  instance.defaults.headers["Authorization"] = `Bearer ${authToken?.replace(
    "Bearer ",
    "",
  )}`;

  if (typeof window !== "undefined") {
    if (!!authToken) {
      localStorage.setItem("authToken", authToken);
    } else {
      localStorage.removeItem("authToken");
      localStorage.removeItem("refreshToken");
    }
  }
};

const setRefreshToken = (inputRefreshToken?: Nullable<string>) => {
  if (typeof window !== "undefined") {
    if (!!inputRefreshToken) {
      localStorage.setItem("refreshToken", inputRefreshToken);
    } else {
      localStorage.removeItem("refreshToken");
    }
  }
};

const logout = (shouldRedirect?: boolean) => {
  setAuthToken(undefined);
  setRefreshToken(undefined);

  if (getApps().length > 0) {
    deleteToken(getMessaging());
  }

  if (shouldRedirect) {
    window.location.href = "/login";
  }
};

if (typeof window !== "undefined") {
  setAuthToken(localStorage.getItem("authToken"));
}

export { getInstance, getAuthToken, setAuthToken, setRefreshToken, logout };

import { useCallback, useEffect, useMemo } from "react";
import Router from "next/router";
import * as Sentry from "@sentry/react";

// types
import type User from "types/user";

// constants
import { API_ENDPOINTS } from "constants/api";

// hooks
import useQuery from "hooks/useQuery";
import useMutation from "hooks/useMutation";

// clients
import { getAuthToken, logout as restClientLogout } from "clients/rest";

interface UseUser {
  shouldRedirect?: boolean;
}

const IS_AUTHENTICATED = !!getAuthToken();

function storageChangeListner(event: StorageEvent) {
  if (event.key === "authToken") {
    window.location.reload();
  }
}

export default function useUser({ shouldRedirect = true }: UseUser = {}) {
  const { mutateAsync: logoutRequest } = useMutation(API_ENDPOINTS.AUTH.LOGOUT);

  const {
    data: user,
    isLoading,
    isSuccess,
  } = useQuery<User.UserResponse>(API_ENDPOINTS.USER.ME, {
    enabled: IS_AUTHENTICATED,
    ignoreErrorToast: true,
    onSuccess: (user) => {
      Sentry.setUser({
        id: user._id,
        email: user.email || undefined,
        role: user.role,
      });
    },
  });

  const logout = useCallback(
    (isSoft?: boolean) => {
      if (isSoft) {
        restClientLogout();
        return;
      }

      logoutRequest({})
        .then(() => {
          restClientLogout(true);
        })
        .catch(console.log);
    },
    [logoutRequest],
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (shouldRedirect && !IS_AUTHENTICATED) {
      Router.push("/login");
      return;
    }

    if (shouldRedirect && !user) {
      Router.push("/login");
      return;
    }
  }, [isLoading, shouldRedirect, user]);

  useEffect(() => {
    window.addEventListener("storage", storageChangeListner);

    return () => {
      window.removeEventListener("storage", storageChangeListner);
    };
  }, []);

  const state = useMemo(
    () => ({
      user: user as User.UserResponse,
      isLoading: !isSuccess || isLoading,
      logout,
    }),
    [user, isSuccess, isLoading, logout],
  );

  return state;
}

import React, { useMemo } from "react";
import Image from "next/image";
import Link from "next/link";
import {
  Container,
  Navbar,
  Nav,
  NavDropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import classnames from "classnames";

// hooks
import { useRouter } from "next/router";
import useUser from "hooks/useUser";

const Header = () => {
  const router = useRouter();
  const { user, logout } = useUser({ shouldRedirect: false });

  const routePath = router.asPath;

  const { isLoginRoute, shouldShowLogoOnlyVersion } = useMemo(() => {
    const isLoginRoute = routePath.startsWith("/login");

    const shouldShowLogoOnlyVersion = isLoginRoute;

    return { isLoginRoute, shouldShowLogoOnlyVersion };
  }, [routePath]);

  return (
    <Navbar
      className="position-relative"
      collapseOnSelect
      expand="lg"
      bg="light"
      variant="light"
    >
      <Container
        className={classnames({ "text-center": shouldShowLogoOnlyVersion })}
      >
        <Navbar.Brand
          as="span"
          className={classnames({ "flex-grow-1": shouldShowLogoOnlyVersion })}
        >
          <Link href="/">
            <a>
              <Image
                className="d-inline-block align-top pb-2"
                src="/static/images/mayo-logo-L.png"
                width={100}
                height={60}
                alt=""
              />
              <h1 className="sr-only">Mayo Business</h1>
            </a>
          </Link>
        </Navbar.Brand>
        {!shouldShowLogoOnlyVersion && (
          <>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <div className="me-auto" />
              <Nav className="position-relative">
                {!user && !isLoginRoute && (
                  <Nav.Link as="span" active>
                    <Link href="/login">
                      <a>
                        Login <FontAwesomeIcon icon={solid("arrow-right")} />
                      </a>
                    </Link>
                  </Nav.Link>
                )}
                {user && (
                  <>
                    <Nav.Link
                      as="span"
                      active={routePath.startsWith("/overview")}
                    >
                      <Link href="/overview">
                        <a>Overview</a>
                      </Link>
                    </Nav.Link>
                    <Nav.Link
                      as="span"
                      active={routePath.startsWith("/publishing")}
                    >
                      <Link href="/publishing">
                        <a>Publishing</a>
                      </Link>
                    </Nav.Link>
                    <NavDropdown
                      className="dropdown-menu-end"
                      title={
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id={user.email!}>{user.email}</Tooltip>
                          }
                        >
                          <span>@{user.email!.split("@")[0]}</span>
                        </OverlayTrigger>
                      }
                      id="user-dropdown"
                    >
                      <NavDropdown.Item onClick={() => logout()}>
                        Logout
                      </NavDropdown.Item>
                    </NavDropdown>
                  </>
                )}
              </Nav>
            </Navbar.Collapse>
          </>
        )}
      </Container>
    </Navbar>
  );
};

export default React.memo(Header);

import React, { useEffect } from "react";
import type { AppProps } from "next/app";
import Head from "next/head";
import { ThemeProvider } from "@emotion/react";
import { QueryClient, QueryClientProvider } from "react-query";
import * as Sentry from "@sentry/react";
import { initializeApp, getApps } from "firebase/app";

// constants
import { DEFAULT_THEME } from "constants/theme";

// components
import { Toast } from "components/toast";
import Modal from "components/modal";
import Header from "components/header";
import Footer from "components/footer";
import ErrorBoundary from "components/errorBoundry";

import "assets/scss/global.scss";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
});

if (process.env.NODE_ENV === "development") {
  if (
    typeof window !== "undefined" &&
    window.location.href.indexOf("d=1") > -1
  ) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const whyDidYouRender = require("@welldone-software/why-did-you-render");
    whyDidYouRender(React, {
      trackAllPureComponents: true,
    });
  }
}

function App({ Component, pageProps }: AppProps) {
  useEffect(() => {
    if (!getApps().length) {
      initializeApp({
        apiKey: "AIzaSyCzo4xHtqFTCvq52ASTIKcvyADwwo4BB8s",
        authDomain: "mayo-7996c.firebaseapp.com",
        databaseURL: "https://mayo-7996c.firebaseio.com",
        projectId: "mayo-7996c",
        storageBucket: "mayo-7996c.appspot.com",
        messagingSenderId: "420996576853",
        appId: "1:420996576853:web:0b82fd0d592425155dc6dc",
        measurementId: "G-Q91HF1KD0D",
      });
    }
  }, []);

  return (
    <>
      <Head>
        <title>Mayo Business</title>
      </Head>
      <ThemeProvider theme={DEFAULT_THEME}>
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary>
            <div className="d-flex flex-column min-vh-100">
              <Toast />
              <Modal />
              <Header />
              <main className="flex-shrink-0">
                <Component {...pageProps} />
              </main>

              <Footer />
            </div>
          </ErrorBoundary>
        </QueryClientProvider>
      </ThemeProvider>
    </>
  );
}

export default Sentry.withProfiler(App);
